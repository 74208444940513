export default {
    handleErrosEntrada(errorData, errorForm, numberOfItens) {
        let error = errorData.errors;

        errorForm.campanha = error.campanha ? error.campanha[0] : '';
        errorForm.unidade = error.unidade ? error.unidade[0] : '';
        errorForm.nota_fiscal = error.nota_fiscal ? error.nota_fiscal[0] : '';
        errorForm.emissao = error.emissao ? error.emissao[0] : '';
        errorForm.cnpj = error.cnpj ? error.cnpj[0] : '';
        errorForm.razao_social = error.razao_social ? error.razao_social[0] : '';
        errorForm.observacao = error.observacao ? error.observacao[0] : '';
        errorForm.lotes = error.lotes ? error.lotes[0] : '';
        errorForm.error = '';

        if(!errorForm.lotes) {
            for (let index = 0; index < numberOfItens; index++) {
                errorForm.fabricante[index] = error['lotes.' + index + '.fabricante'] ? 'Item ' +(index+1) + ': ' + error['lotes.' + index +'.fabricante'][0] : null;
                errorForm.validade[index] = error['lotes.' + index + '.validade'] ? 'Item ' +(index+1) + ': ' + error['lotes.' + index +'.validade'][0] : null;
                errorForm.quantidade[index] = error['lotes.' + index + '.quantidade'] ? 'Item ' +(index+1) + ': ' + error['lotes.' + index +'.quantidade'][0] : null;
                errorForm.valor_unitario[index] = error['lotes.' + index + '.valor_unitario'] ? 'Item ' +(index+1) + ': ' + error['lotes.' + index +'.valor_unitario'][0] : null;
            }
        }

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    }
}