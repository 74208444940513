<template>
  <b-modal
    id="add-new-item"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    v-model="showModal"
  >
    <div class="modal-spinner" v-if="loading">
      <b-spinner />
    </div>

    <div v-if="!loading" class="d-flex flex-column my-2">
      <h2>Novo Item</h2>
      <b-alert :show="withLote" variant="danger" class="p-1">
        O número do lote informado já foi inserido na nota fiscal.
      </b-alert>

      <validation-observer ref="itemRules">
        <b-form>
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Lote*" label-for="lote-input">
                <validation-provider
                  #default="{ errors }"
                  name="lote"
                  rules="required"
                >
                  <b-form-input
                    id="lote-input"
                    v-model="item.lote"
                    placeholder="N° do Lote"
                    @blur="searchLote"
                    maxLength="15"
                  />
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <b-form-group
                label="Código Externo"
                label-for="codigo-externo-input"
              >
                <validation-provider
                  #default="{ errors }"
                  name="codigoExterno"
                  rules="max:7"
                >
                  <b-form-input
                    id="codigo-externo-input"
                    v-model="item.codigoExterno"
                    placeholder="Cód. sistema externo"
                    :disabled="disabled.codigoExterno"
                  />
                  <small v-if="errors[0]" class="text-danger">
                    Máximo de 7 caracteres.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Fabricante*" label-for="fabricante-input">
                <validation-provider
                  #default="{ errors }"
                  name="fabricante"
                  rules="required"
                >
                  <b-form-input
                    id="fabricante-input"
                    v-model="item.fabricante"
                    :options="fabricanteSelect"
                    label="descricao"
                    placeholder="Selecione o fabricante"
                    :clearable="true"
                    :disabled="disabled.fabricante"
                  />
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Vacina*" label-for="vacina-input">
                <validation-provider
                  #default="{ errors }"
                  name="vacina"
                  rules="required"
                >
                  <v-select
                    id="vacina-input"
                    v-model="item.vacina"
                    :options="vacinaSelect"
                    label="nome_tecnico"
                    placeholder="Selecione uma vacina"
                    :clearable="true"
                    :disabled="disabled.vacina"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Tipo de frasco*" label-for="dose-input">
                <validation-provider
                  #default="{ errors }"
                  name="tipo_dose"
                  rules="required"
                >
                  <v-select
                    id="dose-input"
                    v-model="item.frasco"
                    :options="frascoSelect"
                    label="descricao"
                    placeholder="Selecione um frasco"
                    :clearable="true"
                    :disabled="disabled.frasco"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Validade*" label-for="validade-input">
                <validation-provider
                  #default="{ errors }"
                  name="validade"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      class="form-control"
                      id="validade-input"
                      v-mask="'##/##/####'"
                      v-model="input.validade"
                      placeholder="00/00/0000"
                      autocomplete="off"
                      @input="setInput"
                      :disabled="disabled.validade"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        class="form-control d-flex align-items-center"
                        locale="pt-BR"
                        v-model="item.validade"
                        button-only
                        right
                        :hide-header="true"
                        label-help="Selecione a data de validade"
                        button-variant="custom-blue"
                        clearable
                        @input="setPicker"
                        :disabled="disabled.validade"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Quantidade*" label-for="quantidade-input">
                <validation-provider
                  #default="{ errors }"
                  name="quantidade"
                  rules="required"
                >
                  <b-form-input
                    id="quantidade-input"
                    v-model="item.quantidade"
                    placeholder="Quantidade de frascos"
                    @keydown="
                      item.quantidade.length == 0 &&
                        $event.key === '0' &&
                        $event.preventDefault()
                    "
                    v-mask="'#######'"
                  />
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Valor Unitário*" label-for="valor-input">
                <validation-provider
                  #default="{ errors }"
                  name="valor"
                  rules="required"
                >
                  <b-form-input
                    id="valor-input"
                    ref="valor"
                    v-model="item.valor"
                    v-money="moneyMask"
                    class="form-control"
                    @keydown="$event.key === '-' && $event.preventDefault()"
                    placeholder="R$0,00"
                    autocomplete="off"
                  />
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12" sm="12">
              <b-form-group label="Observações" label-for="observacao-input">
                <validation-provider name="observacao">
                  <b-form-input
                    id="observacao-input"
                    v-model="item.observacao"
                    placeholder="Observações do item, local de armazenamento, detalhes..."
                    maxLength="200"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <section v-if="!loading" class="d-flex justify-content-center mb-1">
      <b-button
        id="save-entrada"
        class="mr-2 mb-2-sm"
        variant="custom-blue"
        @click.prevent="confirmAction()"
        :disabled="saving"
      >
        <feather-icon icon="CheckIcon" class="mr-50" />
        <span class="align-middle">Inserir Item</span>
        <span
          v-if="saving"
          class="spinner-border spinner-border-sm ml-1"
          role="status"
          aria-hidden="true"
        ></span>
      </b-button>
      <b-button
        id="cancel-entrada"
        :disabled="saving"
        variant="outline-danger"
        @click.prevent="cancelAction"
      >
        <feather-icon icon="XIcon" class="mr-50" />
        <span class="align-middle">Descartar</span>
      </b-button>
    </section>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueCleave from "vue-cleave-component";
import { required, min } from "@validations";
import moment from "moment";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    VBTooltip,
    BSpinner,
    BCard,
    vSelect,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    VueCleave,
    BAlert,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    cleanModal: {
      type: Boolean,
      default: false,
    },
    itemEdit: {
      default: null,
    },
    vacinaSelect: {
      type: Array,
      default() {
        return [];
      },
    },
    fabricanteSelect: {
      type: Array,
      default() {
        return [];
      },
    },
    frascoSelect: {
      type: Array,
      default() {
        return [];
      },
    },
    lotes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      required,
      min,

      loading: false,

      moneyMask: {
        decimal: ",",
        precision: 2,
      },
      item: {
        index: null,
        vacina: null,
        codigoExterno: null,
        fabricante: null,
        frasco: null,
        lote: "",
        validade: "",
        quantidade: "",
        valor: null,
        observacao: "",
      },
      tomorrow: moment().add(1, "days"),
      input: {
        validade: "",
      },
      disabled: {
        vacina: false,
        fabricante: false,
        frasco: false,
        validade: false,
        codigoExterno: false,
      },
      saving: false,
      withLote: false,
    };
  },
  methods: {
    confirmAction() {
      this.withLote = false;
      const lote = this.$props.lotes.filter((l) => l.lote == this.item.lote);

      if (lote.length && this.item.index.length == 0) {
        this.withLote = true;
        return;
      }

      this.$refs.itemRules.validate().then((success) => {
        if (success) {
          const valor_decimal = this.formatCommaDecimal(this.item.valor);
          const total = this.item.quantidade * valor_decimal;
          const quantidade_conversao =
            this.item.quantidade * this.item.frasco.quantidade;
          
          let formattedItem = {
            index: this.item.index,
            vacina: this.item.vacina,
            fabricante: this.item.fabricante,
            codigo_externo: this.item.codigoExterno,
            lote: this.item.lote,
            frasco: this.item.frasco,
            validade: this.item.validade,
            quantidade: this.item.quantidade,
            quantidade_conversao: quantidade_conversao,
            valor_unitario: valor_decimal,
            valor_unitario_conversao:valor_decimal.toFixed(2),
            valor_total: total.toFixed(2),
            observacao: this.item.observacao,
          };

          this.$emit("confirmed", formattedItem);
          this.emptyForm();
          this.$refs.itemRules.reset();
        }
      });
    },
    cancelAction(modalEvent) {
      modalEvent.preventDefault();
      this.$emit("canceled", modalEvent);
      this.$refs.itemRules.reset();
    },
    setPicker() {
      this.input.validade = moment(this.item.validade).format("DD/MM/YYYY");
    },
    setInput() {
      this.item.validade = moment(this.input.validade, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
    },
    fillForm(itemEdit) {
      this.item.lote = itemEdit.lote;
      this.searchLote();
      this.item.index = itemEdit.index;
      this.item.vacina = itemEdit.vacina;
      this.item.codigoExterno = itemEdit.codigo_externo;
      this.item.fabricante = itemEdit.fabricante;
      this.item.frasco = itemEdit.frasco;
      this.item.codigoExterno = itemEdit.codigo_externo;
      this.item.validade = itemEdit.validade;
      this.item.quantidade = itemEdit.quantidade;
      this.item.valor = itemEdit.valor_unitario.toFixed(2);
      this.item.observacao = itemEdit.observacao;
      this.setPicker();
    },
    emptyForm() {
      this.item.index = "";
      this.item.vacina = "";
      this.item.fabricante = "";
      this.item.frasco = "";
      this.item.lote = "";
      this.item.validade = "";
      this.input.validade = "";
      this.item.quantidade = "";
      this.item.observacao = "";
      this.item.codigoExterno = null;
      this.disabled.vacina = false;
      this.disabled.fabricante = false;
      this.disabled.frasco = false;
      this.disabled.validade = false;
      this.disabled.codigoExterno = false;

      if (this.$refs.valor) {
        this.$refs.valor.$el.value = 0.0;
      }
      this.item.valor = "";
    },
    async searchLote() {
      if(this.item.lote.length >= 1) {
        this.loading = true

        this.item.lote = this.item.lote.toUpperCase();
        let idsVacinas = this.vacinaSelect.map((vacina) => vacina.id_vacina);

        const params = {
          lote: this.item.lote,
          id_vacina: idsVacinas,
        };

        await this.$http.get(this.$api.lote(), { params })
            .then(({ data }) => {
              if (data.data.length > 0) {
                const lote = data.data[0];

                this.item.vacina = {
                  id_vacina: lote.id_vacina,
                  nome_tecnico: lote.nome_tecnico_vacina,
                };
                this.item.fabricante = lote.descricao_fabricante;
                this.item.codigoExterno = lote.codigo_externo;
                this.item.frasco = {
                  id_frasco: lote.id_frasco,
                  descricao: lote.descricao_frasco,
                  quantidade: lote.quantidade_frasco,
                };
                this.item.validade = lote.validade;
                this.setPicker();
                this.disabled.vacina = true;
                this.disabled.fabricante = true;
                this.disabled.frasco = true;
                this.disabled.validade = true;
                this.disabled.codigoExterno = true;
              } else {
                this.disabled.vacina = false;
                this.disabled.fabricante = false;
                this.disabled.frasco = false;
                this.disabled.validade = false;
                this.disabled.codigoExterno = false;
              }
            });

        this.loading = false
      }
    },
    formatCommaDecimal(value) {
      return parseFloat(value.replace(/,/g, "."));
    },
  },
  watch: {
    itemEdit(val) {
      if (!val) {
        this.emptyForm();
        return;
      }

      if (val.index != null) {
        this.fillForm(val);
      }
    },
    showModal(value) {
      this.withLote = false;
      if (value) {
        if (this.vacinaSelect.length == 1) {
          this.item.vacina = this.vacinaSelect[0];
        }
        if (this.frascoSelect.length == 1) {
          this.item.frasco = this.frascoSelect[0];
        }
      }
    },
    cleanModal(value) {
      if (value) {
        this.emptyForm();
      }
    },
  },
};
</script>

<style scoped>
.bloco {
  height: 200px;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.panel-enter-active,
.panel-leave-active {
  transition: all 0.5s ease;
}
.panel-enter-from,
.panel-leave-to {
  opacity: 0;
  height: 0;
  transform: translateX(30px);
}

.modal-spinner {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
